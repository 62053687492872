import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import { Card, CardContent } from '../common/Card';

export const Categories: React.FC = () => {
  const {
    categories: { group: categories },
  } = useStaticQuery(
    graphql`
      query {
        categories: allMarkdownRemark(
          limit: 2000
          filter: {
            frontmatter: { layout: { eq: "post" }, draft: { ne: true } }
          }
        ) {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return (
    <Card>
      <CardContent>
        <Title>カテゴリ</Title>
        <MenuList>
          {categories.map(
            (category: { fieldValue: string; totalCount: number }) => {
              return (
                <Item key={category.fieldValue}>
                  <CategoryLink
                    to={`/categories/${kebabCase(category.fieldValue)}/`}
                  >
                    <span>
                      <CategoryTitle>{category.fieldValue}</CategoryTitle>
                    </span>
                    <span>
                      <CategoryCount>{category.totalCount}</CategoryCount>
                    </span>
                  </CategoryLink>
                </Item>
              );
            }
          )}
        </MenuList>
      </CardContent>
    </Card>
  );
};

const Title = styled.h3`
  line-height: normal;
  margin-top: 0;
`;

const MenuList = styled.ul`
  margin-bottom: 0;
  list-style: none;
`;

const Item = styled.li`
  &:hover {
    background-color: whitesmoke;
    color: #363636;
  }
  margin-bottom: 0;
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  color: #363636;
  padding: 8px 0;
  font-size: 14px;
  &:hover {
    color: #363636;
  }
`;

const CategoryTitle = styled.span`
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
`;

const CategoryCount = styled.span`
  align-items: center;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
`;
